export const documentSentList = [

    {value:"labrslts",label:"HOBP"},
    {value:"labrslts",label:"HOS"},
    {value:"apsreprt",label:"APS"},
    {value:"dghldata",label:"Digital Health Data"},
    {value:"preshist",label:"Script Check or RX HX"},
    {value:"fincinfo",label:"Financial Info"},
    {value:"reintrns",label:"Reinsurance Transmittal"},
    {value:"E-Worksheet",label:"E-Worksheet"}
]

export const App = [
    {value:"beneupdt",label:"BU/FBI Application"},
    {value:"diapppta",label:"DI App Part A"},
    {value:"diappptc",label:"DI App Part C"},
];

export const Ecg = [
    {value:"ekgecgex",label:"ECG"},
    {value:"ekgecgex",label:"EKG"},
];

export const Exam = [
    {value:"mediexam",label:"Exam (Principal)"},
    {value:"medexnon",label:"Exam (Non-Principal)"},
    {value:"labrslts",label:"Lab results"},
];

export const PtiTeleapp = [
    {value:"diappptb",label:"App Part B"},
    {value:"tappinsd",label:"Teleapp"},
];