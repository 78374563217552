import React,{useState} from 'react';
import { useFormik } from 'formik';
import { documentSentList,App,Ecg,Exam,PtiTeleapp } from './DocumentSent';
import { TransmittalValidationSchema } from './TransmittalValidationSchema';

function Transmittal(props) {

    const [docSelected,setDocSelected] = useState(["Reinsurance Transmittal","E-Worksheet"]);

    const formik = useFormik({
        initialValues:{
            proposedInsured:"",
            principalFileNo:"",
            occupationClass:"",
            dob:"",
            reinsuranceFileNo:"",
            additionalPapers:"",
            disabilityIncomeAmountApplied:"",
            overheadExpenseAmountApplied:"",
            buyoutAmountApplied:"",
            disabilityIncomeAmountInForce:"",
            overheadExpenseAmountInForce:"",
            buyoutAmountInForce:"",
            disabilityIncomeEliPeriod:"",
            overheadExpenseEliPeriod:"",
            buyoutEliPeriod:"",
            disabilityIncomeBenefitPeriod:"",
            overheadExpenseBenefitPeriod:"",
            buyoutBenefitPeriod:"",
            multiLifePrincipalFileNo:"",
            occupationRecommendation:"",
            financialRecommendation:"",
            medicalRecommendation:"",
            pdfFile:"",
            documentFollow:"",
            submittedBy:""
        },
        onSubmit:(values,onSubmitProps)=>{
            const payload = new FormData();
            const formData = values;
            
            formData.documentList = docSelected
            payload.append("transmittal", new Blob([JSON.stringify(formData)],{
                type:"application/json"
            }))
            payload.append("pdfFile",formData.pdfFile)
            props.addTransmittalForm(payload)
            setDocSelected(["Reinsurance Transmittal","E-Worksheet"])
            onSubmitProps.resetForm()
        },
        validationSchema:TransmittalValidationSchema
    })

    const handleOnChange = (e) => {
        let {value, checked} = e.target
        if(checked){
            setDocSelected([...docSelected,value])
        }else{
            setDocSelected(docSelected.filter(x => x !== value))
        }
    }


    // render() { 
        return (
            <div className='container mt-5'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 pds-erroredInput">
                            <div className="pds-labeledInput">
                                <label htmlFor="proposedInsured" className="pds-label pds-typography-bold">
                                Proposed Insured
                                <span className="pds-label-required" aria-hidden="true">
                                    *
                                </span>
                                </label>
                                <input 
                                type="text" 
                                className="pds-validation"
                                id="proposedInsured"
                                name="proposedInsured"
                                aria-required="true"
                                aria-describedby="errorProposedInsured"
                                onChange={formik.handleChange}
                                value={formik.values.proposedInsured}
                                onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.proposedInsured && formik.errors.proposedInsured ?
                            <div className="pds-error">
                                <span
                                    className="pds-util-flex-align-center"
                                    id="errorProposedInsured"
                                >
                                {formik.errors.proposedInsured}
                                </span>
                            </div> : null }

                        </div>

                        <div className="col-md-6 pds-labeledInput pds-erroredInput">
                            <label htmlFor="principalFileNo" className="pds-label pds-typography-bold">
                                Principal File #
                                <span className="pds-label-required" aria-hidden="true">*</span>
                            </label>
                            <input
                            type="text"
                            className="pds-validation"
                            id="principalFileNo"
                            name="principalFileNo"
                            placeholder="Enter principal file no..."
                            aria-required="true"
                            aria-describedby="errorPrincipalFileNo"
                            onChange={formik.handleChange}
                            value={formik.values.principalFileNo}
                            onBlur={formik.handleBlur}
                            />
                            {formik.touched.principalFileNo && formik.errors.principalFileNo ?
                            <div className="pds-error">
                                <span
                                    className="pds-util-flex-align-center"
                                    id="errorProposedInsured"
                                >
                                {formik.errors.principalFileNo}
                                </span>
                            </div> : null }
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-4 pds-labeledInput pds-erroredInput">
                            <label htmlFor="occupationClass" className="pds-label pds-typography-bold">
                                Occupation Class
                                <span className="pds-label-required" aria-hidden="true">*</span>
                            </label>
                            <input
                            type="text"
                            className="pds-validation"
                            id="occupationClass"
                            name="occupationClass"
                            placeholder="Enter Occupation.."
                            aria-describedby="errorOccupationClass"
                            onChange={formik.handleChange}
                            value={formik.values.occupationClass}
                            onBlur={formik.handleBlur}
                            />
                            {formik.touched.occupationClass && formik.errors.occupationClass ?
                            <div className="pds-error">
                                <span
                                    className="pds-util-flex-align-center"
                                    id="errorProposedInsured"
                                >
                                {formik.errors.occupationClass}
                                </span>
                            </div> : null }
                        </div>

                        <div className="col-md-4 pds-labeledInput pds-erroredInput">
                            <label htmlFor="dob" className="pds-label pds-typography-bold">
                                DOB
                                <span className="pds-label-required" aria-hidden="true">*</span>
                            </label>
                            <input
                                placeholder="MM/DD/YYYY"
                                className="pds-validation"
                                type="date"
                                name="dob"
                                id="dob"
                                aria-describedby="errorDob"
                                onChange={formik.handleChange}
                                value={formik.values.dob}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.dob && formik.errors.dob ?
                            <div className="pds-error">
                                <span
                                    className="pds-util-flex-align-center"
                                    id="errorProposedInsured"
                                >
                                {formik.errors.dob}
                                </span>
                            </div> : null }
                        </div>

                        <div className="col-md-4 pds-labeledInput pds-erroredInput">
                            <label htmlFor="reinsuranceFileNo" className="pds-label pds-typography-bold">
                                Reinsurer File #
                            </label>
                            <input
                            type="text"
                            className="pds-validation"
                            id="reinsuranceFileNo"
                            name="reinsuranceFileNo"
                            placeholder="Enter Reinsurance file no.."
                            aria-describedby="errorReinsuranceFileNo"
                            onChange={formik.handleChange}
                            value={formik.values.reinsuranceFileNo}
                            onBlur={formik.handleBlur}
                            />
                            {formik.touched.reinsuranceFileNo && formik.errors.reinsuranceFileNo ?
                            <div className="pds-error">
                                <span
                                    className="pds-util-flex-align-center"
                                    id="errorProposedInsured"
                                >
                                {formik.errors.reinsuranceFileNo}
                                </span>
                            </div> : null }
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="form-group col-md-4">
                            <div className="pds-checkbox">
                                <input 
                                    name="additionalPapers" 
                                    id="additionalPapers"  
                                    type="checkbox" 
                                    onChange={formik.handleChange}
                                    value={formik.values.additionalPapers}
                                    onBlur={formik.handleBlur}
                                />
                                <label htmlFor="additionalPapers" className="pds-label pds-typography-bold">
                                    Additional Papers only
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='pds-form-field' style={{marginTop:"10px",marginBottom:"18px"}}>
                    <table className="pds-table pds-table-striped-odd" 
                    style={{position:"relative",zIndex:0}}
                    >
                        <thead>
                            <tr>
                                <th scope="col" >#</th>
                                <th scope="col" >Disability Income
                                    <span className="pds-label-required" style={{color:"#C00000"}} aria-hidden="true">
                                        *
                                    </span>
                                </th>
                                <th scope="col" >Overhead Expense</th>
                                <th scope="col" >Buyout</th>
                            </tr>
                        </thead> 
                        <tbody>
                              <tr>
                                <th scope="row">Amount applied for</th>
                                <td>
                                    <div className="pds-input-icon-left">
                                    <input
                                        type="text"
                                        id="disabilityIncomeAmountApplied"
                                        name="disabilityIncomeAmountApplied"
                                        onChange={formik.handleChange}
                                        value={formik.values.disabilityIncomeAmountApplied}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span className="pds-input-icon" aria-hidden="true">$</span>
                                    </div>
                                    {formik.touched.disabilityIncomeAmountApplied && formik.errors.disabilityIncomeAmountApplied ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.disabilityIncomeAmountApplied}
                                            </span>
                                    </div> : null }
                                </td>
                                <td>
                                    <div className="pds-input-icon-left">
                                        <input
                                            type="text"
                                            id="overheadExpenseAmountApplied"
                                            name="overheadExpenseAmountApplied"
                                            onChange={formik.handleChange}
                                            value={formik.values.overheadExpenseAmountApplied}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span className="pds-input-icon" aria-hidden="true">$</span>
                                    </div>
                                    {formik.touched.overheadExpenseAmountApplied && formik.errors.overheadExpenseAmountApplied ?
                                    <div className="pds-error">
                                        <span
                                            className="pds-util-flex-align-center"
                                            id="errorProposedInsured"
                                        >
                                        {formik.errors.overheadExpenseAmountApplied}
                                        </span>
                                    </div> : null }
                                </td>
                                <td>
                                    <div className="pds-input-icon-left">
                                        <input
                                            type="text"
                                            id="buyoutAmountApplied"
                                            name="buyoutAmountApplied"
                                            onChange={formik.handleChange}
                                            value={formik.values.buyoutAmountApplied}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span className="pds-input-icon" aria-hidden="true">$</span>
                                    </div>
                                    {formik.touched.buyoutAmountApplied && formik.errors.buyoutAmountApplied ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.buyoutAmountApplied}
                                            </span>
                                    </div> : null }
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Amount in force</th>
                                <td>
                                    <div className="pds-input-icon-left">
                                    <input
                                        type="text"
                                        id="disabilityIncomeAmountInForce"
                                        name="disabilityIncomeAmountInForce"
                                        onChange={formik.handleChange}
                                        value={formik.values.disabilityIncomeAmountInForce}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span className="pds-input-icon" aria-hidden="true">$</span>
                                    </div>
                                    {formik.touched.disabilityIncomeAmountInForce && formik.errors.disabilityIncomeAmountInForce ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.disabilityIncomeAmountInForce}
                                            </span>
                                    </div> : null }
                                </td>
                                <td>
                                    <div className="pds-input-icon-left">
                                    <input
                                        type="text"
                                        id="overheadExpenseAmountInForce"
                                        name="overheadExpenseAmountInForce"
                                        onChange={formik.handleChange}
                                        value={formik.values.overheadExpenseAmountInForce}
                                        onBlur={formik.handleBlur}
                                    />
                                    <span className="pds-input-icon" aria-hidden="true">$</span>
                                    </div>
                                    {formik.touched.overheadExpenseAmountInForce && formik.errors.overheadExpenseAmountInForce ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.overheadExpenseAmountInForce}
                                            </span>
                                    </div> : null }
                                </td>
                                <td>
                                    <div className="pds-input-icon-left">
                                        <input
                                            type="text"
                                            id="buyoutAmountInForce"
                                            name="buyoutAmountInForce"
                                            onChange={formik.handleChange}
                                            value={formik.values.buyoutAmountInForce}
                                            onBlur={formik.handleBlur}
                                        />
                                        <span className="pds-input-icon" aria-hidden="true">$</span>
                                    </div>
                                    {formik.touched.buyoutAmountInForce && formik.errors.buyoutAmountInForce ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.buyoutAmountInForce}
                                            </span>
                                    </div> : null }
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Elimination Period</th>
                                <td>
                                    <input
                                        type="text"
                                        id="disabilityIncomeEliPeriod"
                                        name="disabilityIncomeEliPeriod"
                                        onChange={formik.handleChange}
                                        value={formik.values.disabilityIncomeEliPeriod}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.disabilityIncomeEliPeriod && formik.errors.disabilityIncomeEliPeriod ?
                                    <div className="pds-error">
                                        <span
                                            className="pds-util-flex-align-center"
                                            id="errorProposedInsured"
                                        >
                                        {formik.errors.disabilityIncomeEliPeriod}
                                        </span>
                                    </div> : null }
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        id="overheadExpenseEliPeriod"
                                        name="overheadExpenseEliPeriod"
                                        onChange={formik.handleChange}
                                        value={formik.values.overheadExpenseEliPeriod}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.overheadExpenseEliPeriod && formik.errors.overheadExpenseEliPeriod ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.overheadExpenseEliPeriod}
                                            </span>
                                    </div> : null }
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        id="buyoutEliPeriod"
                                        name="buyoutEliPeriod"
                                        onChange={formik.handleChange}
                                        value={formik.values.buyoutEliPeriod}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.buyoutEliPeriod && formik.errors.buyoutEliPeriod ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.buyoutEliPeriod}
                                            </span>
                                    </div> : null }
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Benefit Period</th>
                                <td>
                                    <input
                                        type="text"
                                        id="disabilityIncomeBenefitPeriod"
                                        name="disabilityIncomeBenefitPeriod"
                                        onChange={formik.handleChange}
                                        value={formik.values.disabilityIncomeBenefitPeriod}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.disabilityIncomeBenefitPeriod && formik.errors.disabilityIncomeBenefitPeriod ?
                                    <div className="pds-error">
                                        <span
                                            className="pds-util-flex-align-center"
                                            id="errorProposedInsured"
                                        >
                                        {formik.errors.disabilityIncomeBenefitPeriod}
                                        </span>
                                    </div> : null }
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        id="overheadExpenseBenefitPeriod"
                                        name="overheadExpenseBenefitPeriod"
                                        onChange={formik.handleChange}
                                        value={formik.values.overheadExpenseBenefitPeriod}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.overheadExpenseBenefitPeriod && formik.errors.overheadExpenseBenefitPeriod ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.overheadExpenseBenefitPeriod}
                                            </span>
                                    </div> : null }
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        id="buyoutBenefitPeriod"
                                        name="buyoutBenefitPeriod"
                                        onChange={formik.handleChange}
                                        value={formik.values.buyoutBenefitPeriod}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.buyoutBenefitPeriod && formik.errors.buyoutBenefitPeriod ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.buyoutBenefitPeriod}
                                            </span>
                                    </div> : null }
                                </td>
                              </tr>
                            </tbody>
                    </table>
                    </div>

                    <div className="pds-form-field">
                        <div className="pds-labeledInput">
                            <label htmlFor="multiLifePrincipalFileNo" className="pds-label">
                            <strong>If Multi-Life case, names, Principal File #</strong>
                            </label>
                            <input 
                                aria-label="" 
                                type="text"
                                id="multiLifePrincipalFileNo"
                                name="multiLifePrincipalFileNo"
                                rows="3"
                                onChange={formik.handleChange}
                                value={formik.values.multiLifePrincipalFileNo}
                                onBlur={formik.handleBlur}
                             />
                        </div>
                    </div>

                    <div className="pds-form-field">
                        <div className="pds-labeledInput">
                            <label htmlFor="occupationRecommendation" className="pds-label">
                            <strong>Occupation Concerns & Recommendations</strong>
                            </label>
                            <input 
                                aria-label="" 
                                type="text"
                                id="occupationRecommendation"
                                name="occupationRecommendation"
                                rows="3"
                                onChange={formik.handleChange}
                                value={formik.values.occupationRecommendation}
                                onBlur={formik.handleBlur}
                             />
                        </div>
                    </div>
                    
                    <div className="pds-form-field">
                        <div className="pds-labeledInput">
                            <label htmlFor="financialRecommendation" className="pds-label">
                            <strong>Financial Concerns & Recommendations</strong>
                            </label>
                            <input 
                                aria-label="" 
                                type="text"
                                id="financialRecommendation"
                                name="financialRecommendation"
                                rows="3"
                                onChange={formik.handleChange}
                                value={formik.values.financialRecommendation}
                                onBlur={formik.handleBlur}
                             />
                        </div>
                    </div>

                    <div className="pds-form-field">
                        <div className="pds-labeledInput">
                            <label htmlFor="medicalRecommendation" className="pds-label">
                            <strong>Medical Concerns & Recommendations</strong>
                            </label>
                            <input 
                                aria-label="" 
                                type="text"
                                id="medicalRecommendation"
                                name="medicalRecommendation"
                                rows="3"
                                onChange={formik.handleChange}
                                value={formik.values.medicalRecommendation}
                                onBlur={formik.handleBlur}
                             />
                        </div>
                    </div>
                
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="pds-fileUpload" data-drop-zone-configuration="box">
                                <label className="pds-label pds-typography-bold" htmlFor="pdfFile">
                                    EWS Form Upload
                                    <span className="pds-label-required" aria-hidden="true">
                                        *
                                    </span>{" "}
                                    {formik.touched.pdfFile && formik.errors.pdfFile ?
                                        <div className="pds-error">
                                            <span
                                                className="pds-util-flex-align-center"
                                                id="errorProposedInsured"
                                            >
                                            {formik.errors.pdfFile}
                                            </span>
                                    </div> : null }
                                </label>
                                <div className="pds-helperText" id="pds-file-upload-helper-text-single">
                                only pdf file is allowed.
                                </div>
                                <input
                                type="file"
                                id="pdfFile"
                                name="pdfFile"
                                className="sr-only"
                                aria-describedby="pds-file-upload-helper-text-single"
                                accept="application/pdf"
                                data-file-size={100}
                                onChange={(e) => formik.setFieldValue('pdfFile',e.target.files[0])}
                                onBlur={formik.handleBlur}
                                />
                                <div className="pds-fileUpload-drop-zone" />
                            </div>
                            
                        </div>
                        <div className="col-md-6 mb-5">
                            
                            <label htmlFor="documentList" ><strong>Select Documents to be sent</strong></label><br/>
                            {
                                documentSentList.map((ele,i) =>
                                    <div className="pds-checkbox" key={ele.label}>
                                        <input
                                            type="checkbox"
                                            name="documentList"
                                            id={ele.label}
                                            value={ele.label}
                                            defaultChecked={ele.label==='Reinsurance Transmittal' || ele.label==='E-Worksheet'}
                                            disabled={ele.label==='Reinsurance Transmittal'||ele.label==='E-Worksheet'}
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                        <label htmlFor={ele.label} key={i} className="pds-label">{ele.label}</label>
                                    </div>
                                    )
                            }
                            <strong><label>App</label></strong><br/>
                            {
                                App.map((ele,i) => 
                                    <span className="pds-checkbox" key={ele.label}>
                                        <input
                                            type="checkbox"
                                            name="documentList"
                                            id={ele.label}
                                            value={ele.label}
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                        <label htmlFor={ele.label} key={i} className="pds-label">{ele.label}</label>{"  "}
                                    </span>
                                )
                            }
                            <br/><strong><label>EXAM</label></strong><br/>
                            {
                                Exam.map((ele,i) => 
                                    <span className="pds-checkbox" key={ele.label}>
                                        <input
                                            type="checkbox"
                                            name="documentList"
                                            id={ele.label}
                                            value={ele.label}
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                        <label htmlFor={ele.label} key={i} className="pds-label">{ele.label}</label>{"  "}
                                    </span>
                                )
                            }
                            <br/><strong><label>EKG</label></strong><br/>
                            {
                                Ecg.map((ele,i) => 
                                    <span className="pds-checkbox" key={ele.label}>
                                        <input
                                            type="checkbox"
                                            name="documentList"
                                            id={ele.label}
                                            value={ele.label}
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                        <label htmlFor={ele.label} key={i} className="pds-label">{ele.label}</label>{"  "}
                                    </span>
                                )
                            }
                            <br/><strong><label>PTI/Teleapp</label></strong><br/>
                            {
                                PtiTeleapp.map((ele,i) => 
                                    <span className="pds-checkbox" key={ele.label}>
                                        <input
                                            type="checkbox"
                                            name="documentList"
                                            id={ele.label}
                                            value={ele.label}
                                            onChange={(e) => handleOnChange(e)}
                                        />
                                        <label htmlFor={ele.label} key={i} className="pds-label">{ele.label}</label>{"  "}
                                    </span>
                                )
                            }
                        </div>
                        
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="documentFollow" className="pds-label pds-typography-bold">Documents to follow</label>
                            <input
                            type="text"
                            id="documentFollow"
                            name="documentFollow"
                            onChange={formik.handleChange}
                            value={formik.values.documentFollow}
                            onBlur={formik.handleBlur}
                            />
                        </div>
                        <div className="col-md-6 pds-erroredInput">
                            <div className="pds-labeledInput">
                                <label htmlFor="submittedBy" className="pds-label pds-typography-bold">
                                Submitted By
                                <span className="pds-label-required" aria-hidden="true">
                                    *
                                </span>
                                </label>
                                <input 
                                type="text" 
                                className="pds-validation mt-0"
                                id="submittedBy"
                                name="submittedBy"
                                placeholder='Enter Email Address'
                                aria-required="true"
                                aria-describedby="errorSubmittedBy"
                                onChange={formik.handleChange}
                                value={formik.values.submittedBy}
                                onBlur={formik.handleBlur}
                                />
                            </div>
                            {formik.touched.submittedBy && formik.errors.submittedBy ?
                            <div className="pds-error">
                                <span
                                    className="pds-util-flex-align-center"
                                    id="errorProposedInsured"
                                >
                                {formik.errors.submittedBy}
                                </span>
                            </div> : null }
                        </div>
                    </div>
                    <div className="row mt-3 mb-3">
                        <div className="col offset-md-11">
                            <button type="submit" className="pds-button pds-button-primary">Submit</button>
                        </div>
                    </div>
                </form>  
            </div>
        );
    }
// } //render method
 
export default Transmittal;