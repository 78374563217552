import React from 'react';

const ToastMessage = (props) => {

   
    // render() { 
        return (
            // alert
            <div className="container" style={{marginTop:"15px"}}>
                <div className="pds-alert pds-alert-success">
                    <div className="pds-card-section">
                        <div className="pds-alert-content" role="alert">
                        <span className="sr-only">Success. </span>
                        {props.msg}
                        </div>
                        <button type="button" className="pds-alert-close" aria-label="Close">X</button>
                    </div>
                </div>
            </div>

            // modal
            // <div className="pds-modal" id="pds-modal">
            //     {/* {" "}
            //     <button
            //         type="button"
            //         className="pds-button pds-button-secondary pds-modal-open-dialog"
            //     >
            //         Open the modal
            //     </button> */}
            //     <div
            //         className="pds-modal-dialog"
            //         role="dialog"
            //         aria-labelledby="modal-title"
            //         aria-hidden="true"
            //     >
            //         <button
            //         className="pds-modal-close-dialog pds-modal-close"
            //         type="button"
            //         aria-label="Close"
            //         ></button>
            //         <h1 id="modal-title" className="sr-only" data-gtm="modal-title">
            //         {" "}
            //         Modal title for screen reader
            //         </h1>
            //         <p>
            //         <span className="pds-typography-bold">{this.props.msg}</span>
            //         </p>
            //         <div className="pds-modal-cta">
            //         <button
            //             className="pds-button pds-button-primary pds-modal-close-dialog"
            //             data-gtm="modal-cta"
            //         >
            //             Close
            //         </button>
            //         </div>
            //     </div>
            // </div>

        );
    }
// }
 
export default ToastMessage;