import React from 'react';

export const NotFoundPage = () => (
  
<div className="pds-notFoundError-background">
  <section className="pds-imgCarrollTheLibrarian">
    <div className="pds-typography-center">
      <div className="pds-util-padding-horz-10">
        <h1 className="pds-typography-h2 pds-util-margin-top-20 pds-util-margin-bottom-10">We're sorry.</h1>
        <p className="pds-util-margin-top-0 pds-util-margin-bottom-10">We've looked everywhere and can't seem to find the page you're looking for.</p>
      </div>
      <p className="pds-util-margin-top-0 pds-util-margin-bottom-10 pds-util-padding-horz-10"><a href="/">Head back to the previous page</a></p>
    </div>
  </section>
</div>
)