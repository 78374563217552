import * as Yup from 'yup';

const today = new Date();
today.setHours(0,0,0,0)

export const TransmittalValidationSchema = Yup.object().shape({
    proposedInsured: Yup.string().required("Proposed Insured can't be empty.")
            .matches(/^[aA-zZ\s.]+$/,"only alphabets are allowed."),
    principalFileNo: Yup.string()
            .required("Principal File # is Required.")
            .matches(/^[0-9]+$/,'Must be only digits.')
            .matches(/^(?:^|\D)(\d{7,8})(?!\d)$/,"Either 7 or 8 digits only."),
    occupationClass:Yup.string()
            .required("Occupation class can't be empty."),
    dob:Yup.date()
            .required("Please provide DOB.")
            .max(today,"Future Date is not allowed."),
    submittedBy:Yup.string()
            .email("Invalid Email Address.")
            .required('Submitted by is Required.'),
    disabilityIncomeAmountApplied:Yup.string()
            .required("Required.")
            .matches(/^[0-9.]+$/,'Must be only digits.')
            .matches(/^\d+(\.\d+)?$/,'Invalid Amount'),
    disabilityIncomeBenefitPeriod:Yup.string()
            .required("Required.")
            .matches(/^[0-9]+$/,'Must be only digits.')
            .matches(/^[0-9]{1,2}$/,'Invalid Period'),
    disabilityIncomeAmountInForce:Yup.string()
            .required("Required.")
            .matches(/^[0-9.]+$/,'Must be only digits.')
            .matches(/^\d+(\.\d+)?$/,'Invalid Amount'),
    disabilityIncomeEliPeriod:Yup.string()
            .required("Required.")
            .matches(/^[0-9]+$/,'Must be only digits.')
            .matches(/^(?:^|\D)(\d{2,3})(?!\d)$/,'Invalid Period'),

            
        //     overhead expense  
        overheadExpenseAmountApplied:Yup.string()
            .matches(/^[0-9.]+$/,'Must be only digits.')
            .matches(/^\d+(\.\d+)?$/,'Invalid Amount'),
        overheadExpenseBenefitPeriod:Yup.string()
            .matches(/^[0-9]+$/,'Must be only digits.')
            .matches(/^[0-9]{1,2}$/,'Invalid Period'),
        overheadExpenseAmountInForce:Yup.string()
            .matches(/^[0-9.]+$/,'Must be only digits.')
            .matches(/^\d+(\.\d+)?$/,'Invalid Amount'),
        overheadExpenseEliPeriod:Yup.string()
            .matches(/^[0-9]+$/,'Must be only digits.')
            .matches(/^(?:^|\D)(\d{2,3})(?!\d)$/,'Invalid Period'),


        //     buyout expense 
        buyoutAmountApplied:Yup.string()
            .matches(/^[0-9.]+$/,'Must be only digits.')
            .matches(/^\d+(\.\d+)?$/,'Invalid Amount'),
        buyoutBenefitPeriod:Yup.string()
            .matches(/^[0-9]+$/,'Must be only digits.')
            .matches(/^[0-9]{1,2}$/,'Invalid Period'),
        buyoutAmountInForce:Yup.string()
            .matches(/^[0-9.]+$/,'Must be only digits.')
            .matches(/^\d+(\.\d+)?$/,'Invalid Amount'),
        buyoutEliPeriod:Yup.string()
            .matches(/^[0-9]+$/,'Must be only digits.')
            .matches(/^(?:^|\D)(\d{2,3})(?!\d)$/,'Invalid Period'),
    
    pdfFile:Yup.mixed().required("Please Attach EWS file.")
            // .test("fileSize","File size is too large",(value)=>{
            //     return value && value[0].size <= 1000;
            // })
            // .test("type","only pdf file is allowed.",(value)=>{
            //     return value && value[0].type === "application/pdf"
            // })
})


