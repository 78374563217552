import { useMsal } from '@azure/msal-react';
import { Container, LinearProgress, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

export const LoginCallBack = () => {
  const { accounts, inProgress } = useMsal();
  // console.log({ accounts, inProgress });
  let message = 'nothing';
  if (inProgress !== 'none') {
    message = `Login in progress`;
    // console.log(message);
  } else if (accounts.length > 0) {
    // console.log('redirect to home');
    return <Redirect to="/"/>;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom></Typography>
      <LinearProgress />
      <LinearProgress color="secondary" />
      <Typography variant="h4" component="h4" gutterBottom>
        {process.env.REACT_APP_NAME}
      </Typography>
      <Typography variant="h4" component="h4" gutterBottom>
        {message}
      </Typography>
    </Container>
  );
};