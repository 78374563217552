import React, { Component } from 'react';

class PageHeader extends Component {
    state = {  } 
    render() { 
        return (
            <div className="container" style={{marginTop:"5px"}}>
                <div className='row'>
                    <div className='col-12' >
                        <label className='pds-typography-h2' style={{float:"left"}}>
                            Reinsurance {" "}
                            <small className='pds-typography-h6'>
                                Transmittal Form
                            </small>
                        </label>
                        <label htmlFor="" style={{float:"right", paddingTop:"20px"}} className="pds-label pds-typography-bold">
                        Required
                        <span className="pds-label-required" aria-hidden="true">
                            *
                        </span>
                        </label>
                    </div>
                </div>
                <hr></hr>
            </div>
        );
    }
}
 
export default PageHeader;