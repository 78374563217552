import React ,{useState} from 'react'
import Spinner from './Spinner';


const useFullPageLoader = () => {
    const [loading,setLoading] = useState(false);
    return [
        loading ? <Spinner/> : null,
        () => setLoading(true), //show loader
        () => setLoading(false), //hide loader

    ];
};


export default useFullPageLoader;
