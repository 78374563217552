export const msalConfig = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY_URL,
    clientId: process.env.REACT_APP_CLIENT_ID,
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL,
    redirectUri: process.env.REACT_APP_REDIRECT_URL
    // redirectUri: "http://localhost:3000/idi/login-callback"

  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
    secureCookies: true
  },
};

export const msalRequest = {
  scopes: process.env.REACT_APP_SCOPE.split(' ') || [],
  redirectStartPage: process.env.REACT_APP_REDIRECT_URL
  // redirectStartPage: "http://localhost:3000/idi/login-callback"
};