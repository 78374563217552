import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './components/Footer';
import PageHeader from './components/PageHeader';
import ToastMessage from './components/ToastMessage';
import { EventType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from '@azure/msal-react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { LogoutInProgress } from './components/loggingout';
import { Login } from './components/login';
import { LoginCallBack } from './components/loginCallback';
import { Navigation } from './components/Navigation';
import { NotFoundPage } from './components/notFound';
import { msalRequest } from './oauthConfig';
import axios from 'axios';
import Transmittal from './components/Transmittal';
import useFullPageLoader from './components/useFullPageLoader';

function App() {

  const [status,setStatus] = useState("")
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = accounts.length > 0;
  const [tabvalue, setTabvalue] = useState(1);
  const [loader,showLoader,hideLoader] = useFullPageLoader();



  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
          instance.setActiveAccount(message.payload.account);
          // Update UI
        } else if (message.eventType === EventType.ACCOUNT_REMOVED) {
        }
      }
      
    );
    
    return () => {
      instance.disableAccountStorageEvents();
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  


  const addTransmittalForm = async (data) =>{
    showLoader();

    const token = instance.acquireTokenSilent(msalRequest);
    token.then(function(res){

      axios.post(process.env.REACT_APP_BACKEND_URL,data,{    
        headers:{
          "Content-Type":"multipart/form-data",
          "Authorization": "Bearer "+res.accessToken
        },
      })
      .then(res => {
        if(res.data['statusCode']){
          // success alert
          window.scrollTo(0,0);
          hideLoader();
          setStatus(res.data['body'])
          setTimeout(()=>{
            window.location.reload()
          },3000);
      }
      })
      .catch((err)=>{
      })
    }).catch(function(err){
    })
    
  }

  
  return (
    <BrowserRouter basename="/idi">
      <Navigation
        authStatus={isAuthenticated}
        currentTab={tabvalue}
        updateTab={setTabvalue}
      />

      <Switch>
        <Route
          exact
          path="/"
          render={(props) =>
            inProgress === 'none' ? (
              <>
                <AuthenticatedTemplate>
                  {loader}
                   {status.length > 0 ? <ToastMessage msg={status}/> : null}
                   <PageHeader/>
                   <main>
                     <Transmittal 
                     addTransmittalForm={addTransmittalForm} 
                     />
                   </main>
                  <Footer/>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Redirect to="/login" />
                </UnauthenticatedTemplate>
              </>
            ) : (
              <>
                <div className="row justify-content-center">
                  <div className="col col-bounded pds-typography-center pds-util-padding-top-60">
                    <h1 className="pds-typography-h3">Loading</h1>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div
                    className="col col-bounded pds-typography-center"
                    style={{ paddingTop: '10px', paddingBottom: '40px' }}
                  >
                    <div
                      className="pds-util-inline-flex pds-animatedIcon-rotate"
                      aria-label="loading"
                    >
                      <i
                        data-feather="loader"
                        aria-hidden="true"
                        style={{
                          color: '#0091da',
                          width: '75px',
                          height: '75px',
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </>
            )
          }
        ></Route>
        <Route exact path="/login" component={Login} />
        <Route exact path="/login-callback" component={LoginCallBack} />
        <Route exact path="/logout" component={LogoutInProgress} />
        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );

}

export default App;
