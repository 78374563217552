import { useMsal } from '@azure/msal-react';
import { Container, Typography } from '@material-ui/core';
import { msalRequest } from '../oauthConfig';

export const Login = () => {
  const { instance } = useMsal();

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h4" gutterBottom>
        {process.env.REACT_APP_NAME}
      </Typography>
      <button
        className="pds-button pds-button-primary"
        onClick={() => {
          instance.loginRedirect(msalRequest);
        }}
      >
        <i data-feather="unlock" aria-hidden="true"></i> &nbsp; LOGIN
      </button>
    </Container>
  );
};