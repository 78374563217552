import React from 'react';

const Spinner = (props) => {

   
        return (
        <div className='pds-typography-center overlayy pds-color-font-primary'>
            <div className="pds-alert">
                <i data-feather="loader" aria-hidden="true" height="150" width="150" /><br/>
                <div className="pds-card-section">
                    <h2 className="pds-card-title">Processing</h2>
                </div>
            </div>
        </div>
        );
}
 
export default Spinner;