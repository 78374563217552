import { Container, LinearProgress, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {msalConfig} from '../oauthConfig'
import { PublicClientApplication } from '@azure/msal-browser';
const msalInstance = new PublicClientApplication(msalConfig);

const logout = () => {
  msalInstance.logoutRedirect();
  msalInstance.logoutPopup();
};

export const LogoutInProgress = () => {

  const [loggedout, setLoggedout] = useState(false);

  useEffect(() => {
    setLoggedout(true);
      logout();
  }, []);
  if (loggedout) {
    return <Redirect to="/" />;
  } else {
    return (
      <Container maxWidth="md">
        <Typography variant="h4" component="h1" gutterBottom></Typography>
        <LinearProgress />
        <LinearProgress color="secondary" />
        <Typography variant="h4" component="h4" gutterBottom>
          {process.env.REACT_APP_NAME}
        </Typography>
        <Typography variant="h4" component="h4" gutterBottom>
          logging out in progress
        </Typography>
      </Container>
    );
  }
};