export const Navigation = ({ authStatus, currentTab, updateTab }) => {

    return (
      <nav className="pds-navigationPrimary pds-navigationPrimary-primary">
        <div className="pds-navigationPrimary-container container">
          <a
            className="pds-navigationPrimary-skip-to-main pds-link-unstyled"
            href="#main"
          >
            Skip to content
          </a>
          <div className="pds-navigationPrimary-logo">
            <a
              className="pds-navigationPrimary-logo-link pds-link-unstyled"
              href="/#"
              aria-label="Principal Homepage"
            >
              <img alt="" className="pds-imgLogoCompanyNameWhiteRetina" />
            </a>
          </div>
          {/* <div className="pds-navigationPrimary-menu-container">
            {authStatus && (
              <ul className="pds-navigationPrimary-tier-one">
                <NavItem
                  tabId={1}
                  activeTabId={currentTab}
                  setActiveTab={updateTab}
                >
                  PROFILE
                </NavItem>
                <NavItem
                  tabId={2}
                  activeTabId={currentTab}
                  setActiveTab={updateTab}
                >
                  TOKEN
                </NavItem>
                <NavItem
                  tabId={3}
                  activeTabId={currentTab}
                  setActiveTab={updateTab}
                >
                  API CHECK
                </NavItem>
              </ul>
            )}
          </div> */}
          {authStatus && (
            <div className="pds-navigationPrimary-actions-container pds-navigationPrimary-tier-one-menu-item">
              <ul className="pds-navigationPrimary-tier-one">
                <li>
                  <a
                    className="pds-link-unstyled pds-menu-link pds-link-level-0 pds-menu-toggle"
                    // href={ENV_URL[0]}
                    href="/idi/logout"
                  >
                    LOGOUT
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    );
  };
  

  
