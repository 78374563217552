import React, { Component } from 'react';


class Footer extends Component {
    state = {  } 
    render() { 
        return (
//             <footer className="footer" style={{marginTop:"10px"}}>
//   <section className="footer-row">
//     <div className="footer-social-links">
//       <a href="#placeholder" target="_blank">
//         <i className="fa fa-facebook" />
//       </a>
//       <a href="#placeholder" target="_blank">
//         <i className="fa fa-twitter" />
//       </a>
//       <a href="#placeholder" target="_blank">
//         <i className="fa fa-linkedin" />
//       </a>
//       <a href="#placeholder" target="_blank">
//         <i className="fa fa-youtube-play" />
//       </a>
//     </div>
//     <nav>
//       <ul className="nav nav-inline">
//         <li>
//           <a href="https://www.principal.com/terms-of-use">Terms of Use</a>
//         </li>
//         <li>
//           <a href="https://www.principal.com/products-services-disclosures">
//             Disclosures
//           </a>
//         </li>
//         <li>
//           <a href="https://www.principal.com/privacy-policies">Privacy</a>
//         </li>
//         <li>
//           <a href="https://www.principal.com/security-policies">Security</a>
//         </li>
//         <li>
//           <a href="https://www.principal.com/about-us/our-company/policies/report-fraud-or-unethical-conduct">
//             Report Fraud
//           </a>
//         </li>
//         <li>
//           <a href="#placeholder">Site Map</a>
//         </li>
//       </ul>
//     </nav>
//     <div className="footer-legal">
//       <p>© 2016, Principal Financial Services, Inc.</p>
//       <p>
//         Securities offered through Principal Securities, Inc.,{" "}
//         <a href="http://www.sipc.org">member SIPC</a>
//       </p>
//     </div>
//   </section>
// </footer>
<footer
  className="pds-enterpriseFooter-dark"
  data-settings-social="false"
  data-settings-phone-number="800-986-3343"
  data-settings-contact-us="https://www.principal.com/contact-us"
  data-settings-help-topics="https://www.principal.com/help"
/>

        );
    }
}
 
export default Footer;